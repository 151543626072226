import { useFormContext } from 'react-hook-form'

import { Textarea, TextareaProps } from './Textarea'

export const FormTextarea = ({ id, errorMessage, ...rest }: TextareaProps) => {
  const { register } = useFormContext()

  return (
    <Textarea id={id} errorMessage={errorMessage} {...register(id)} {...rest} />
  )
}
