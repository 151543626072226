import { Flex, FlexProps } from '@chakra-ui/react'
import useTranslation from 'next-translate/useTranslation'

interface RangeValueProps extends FlexProps {
  value: number
  isMin: boolean
}

export const RangeValue = ({ value, isMin, ...rest }: RangeValueProps) => {
  const { t } = useTranslation('common')

  return (
    <Flex
      w="80px"
      minW="80px"
      noOfLines={1}
      borderRadius="3px"
      textAlign="center"
      bgColor="brand.gainsboro"
      {...rest}
    >
      {isMin
        ? t('COMMON_FILTER_VOLUME_MIN').toLowerCase()
        : t('COMMON_FILTER_VOLUME_MAX').toLowerCase()}{' '}
      {value}
    </Flex>
  )
}
