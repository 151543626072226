import { Text, VStack } from '@chakra-ui/react'
import { Controller, useFormContext } from 'react-hook-form'

import { Rating, RatingProps } from 'components/common/Rating'

const RATING_SIZE = 10
const RATING_ERROR_MESSAGE = 'Please provide rating.'

interface FormRatingProps extends RatingProps {
  id: string
  defaultValue: number
  ratingSize?: number
}

export const FormRating = ({
  id,
  defaultValue,
  ratingSize = RATING_SIZE,
  ...rest
}: FormRatingProps) => {
  const { control, formState } = useFormContext()
  return (
    <VStack align="start">
      <Controller
        name={id}
        control={control}
        defaultValue={defaultValue}
        render={({ field: { onChange } }) => (
          <Rating
            size={ratingSize}
            ratingValue={defaultValue}
            onRatingChange={onChange}
            onReset={() => {
              onChange(null)
            }}
            {...rest}
          />
        )}
      />
      {formState.errors[id] && (
        <Text color="state.error">{RATING_ERROR_MESSAGE}</Text>
      )}
    </VStack>
  )
}
