import { ForwardedRef, forwardRef, ReactElement, useRef } from 'react'

import {
  Flex,
  FormControl,
  FormLabel,
  HStack,
  LayoutProps,
  Stack,
  StackDirection,
  Text,
  VStack,
} from '@chakra-ui/react'
import {
  ActionMeta,
  Props as ChakraSelectProps,
  Select,
} from 'chakra-react-select'
import useTranslation from 'next-translate/useTranslation'
import { transparentize } from 'polished'

import { ExplanatoryTooltip } from 'components/common/ExplanatoryTooltip'
import { useIsOnTouchscreen } from 'hooks/useIsOnTouchscreen'
import { Colors } from 'theme/constants'

export enum DropdownVariant {
  White = 'white',
}

export type DropdownOption = {
  value: string | number
  label: string
}

export interface DropdownProps extends Omit<ChakraSelectProps, 'onChange'> {
  id: string
  options: DropdownOption[]
  label?: string
  subLabel?: string
  errorMessage?: string
  tooltip?: string
  rightElement?: ReactElement
  hasInputInRightElement?: boolean
  isRightELement?: boolean
  labelDirection?: StackDirection
  hint?: string
  width?: LayoutProps['width']
  hasDropdownIcon?: boolean
  onChange?: (
    newValue: DropdownOption | DropdownOption[] | null,
    actionMeta: ActionMeta<unknown>
  ) => void
  styleVariant?: DropdownVariant
  hasRequiredLabel?: boolean
}

export const Dropdown = forwardRef<HTMLSelectElement, DropdownProps>(
  (
    {
      id,
      label,
      subLabel,
      labelDirection = 'column',
      options,
      errorMessage,
      isDisabled,
      onChange,
      rightElement,
      isRightELement = false,
      hasInputInRightElement = false,
      tooltip,
      width = 'full',
      chakraStyles,
      hasDropdownIcon = true,
      isInvalid,
      isMulti,
      styleVariant,
      hasRequiredLabel = false,
      ...rest
    },
    // TODO: @ivankrzak could make the `any` type here more descriptive
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ref: ForwardedRef<any>
  ) => {
    const { t } = useTranslation('common')
    const internalRef = useRef(null)
    const isTouchscreenDevice = useIsOnTouchscreen()

    return (
      <FormControl id={id} minW={0}>
        <Stack
          justifyContent="space-between"
          direction={labelDirection}
          align="start"
          alignItems={labelDirection === 'row' ? 'center' : 'left'}
          spacing="1"
          w="full"
        >
          {label && !subLabel && (
            <HStack>
              <FormLabel
                variant="input"
                whiteSpace="nowrap"
                mr="0"
                {...(styleVariant === DropdownVariant.White && {
                  color: 'white',
                })}
              >
                {label}
              </FormLabel>
              {tooltip && <ExplanatoryTooltip label={tooltip} />}
            </HStack>
          )}
          {label && subLabel && (
            <VStack spacing={0} alignItems="start">
              <HStack>
                <Text fontSize="13px">{label}</Text>
                {tooltip && <ExplanatoryTooltip label={tooltip} />}
              </HStack>
              <Text color="brand.wildBlueYonder" fontSize="13px">
                {subLabel}
              </Text>
            </VStack>
          )}
          <Flex flexDirection="column">
            <Flex>
              <Select
                id={id}
                name={id}
                ref={ref || internalRef}
                isDisabled={isDisabled}
                options={options}
                hideSelectedOptions={false}
                closeMenuOnSelect={!isMulti}
                focusBorderColor="brand.darkBlueGray"
                onChange={(newValue, actionMeta) => {
                  const value = newValue as
                    | DropdownOption
                    | DropdownOption[]
                    | null
                  onChange?.(value, actionMeta)
                }}
                noOptionsMessage={() => t('TRANSLATION_54')}
                chakraStyles={{
                  valueContainer: (provided) => ({
                    ...provided,
                    border: 'none',
                    bgColor: 'white',
                    fontSize: '14px',
                    lineHeight: '20px',
                  }),
                  control: (provided, { isFocused }) => ({
                    ...provided,
                    w: width,
                    borderColor: 'brand.gainsboro',
                    borderWidth: '1px',
                    borderRadius: isFocused ? '4px 4px 0 0' : '4px',
                    ...(hasInputInRightElement && {
                      borderRight: 'none',
                      borderRadius: isFocused ? '4px 0 0 0' : '4px 0 0 4px',
                    }),
                    ...(isRightELement && {
                      borderRadius: isFocused ? '4px 0 0 0' : '0 4px 4px 0  ',
                    }),
                  }),
                  container: (provided) => ({
                    ...provided,
                    border: 'none',
                    cursor: isDisabled ? 'not-allowed' : 'pointer',
                    w: width,
                    bgColor: 'white',
                    borderRadius: '4px',
                  }),
                  placeholder: (provided) => ({
                    ...provided,
                    color: 'brand.wildBlueYonder',
                    fontSize: isTouchscreenDevice ? 'xm' : 'xs',
                    lineHeight: '20px',
                  }),
                  menu: (provided) => ({
                    ...provided,
                    position: 'absolute',
                    mt: '0px',
                    w: 'full',
                  }),
                  menuList: (provided) => ({
                    ...provided,
                    m: '0px',
                    p: '0px',
                    backgroundColor: 'white',
                    borderColor: 'brand.darkBlueGray',
                    borderTop: 'none',
                    borderRadius: '0 0 4px 4px',
                  }),
                  option: (provided, { isSelected }) => ({
                    ...provided,
                    mt: '0px',
                    color: isSelected
                      ? 'brand.ultramarine'
                      : 'brand.darkSlateBlue',
                    backgroundColor: isSelected
                      ? transparentize(0.75, Colors.brand.ultramarine)
                      : 'white',
                    ':hover': {
                      backgroundColor: isSelected
                        ? 'brand.ultramarine'
                        : 'brand.ghostWhite',
                    },
                    fontFamily: isSelected
                      ? 'Proxima Nova Semibold'
                      : 'Proxima Nova',
                    fontSize: isTouchscreenDevice ? 'xm' : 'xs',
                    lineHeight: '20px',
                    userSelect: 'none',
                    _selected: {
                      backgroundColor: 'brand.ultramarine',
                      color: 'white',
                    },
                  }),
                  clearIndicator: (provided) => ({
                    ...provided,
                    width: '8px',
                    height: '8px',
                    ...(!hasDropdownIcon && { mr: '12px' }),
                  }),
                  crossIcon: (provided) => ({
                    ...provided,
                    color: 'brand.darkSlateBlue',
                    width: '8px',
                    height: '8px',
                  }),
                  loadingIndicator: (provided) => ({
                    ...provided,
                    color: 'gray.500',
                  }),
                  indicatorsContainer: (provided) => ({
                    ...provided,
                    bgColor: 'white',
                  }),
                  indicatorSeparator: (provided) => ({
                    ...provided,
                    display: 'none',
                  }),
                  dropdownIndicator: (provided) => ({
                    ...provided,
                    ...(!hasDropdownIcon && { display: 'none' }),
                    bg: 'white',
                    pl: '5px',
                    pr: '12px',
                    fontSize: isTouchscreenDevice ? 'xm' : 'xs',
                    border: 'none',
                    color: 'brand.darkSlateBlue',
                  }),
                  multiValueLabel: (provided) => ({
                    ...provided,
                    color: 'brand.darkSlateBlue',
                    fontSize: isTouchscreenDevice ? 'xm' : 'xs',
                    lineHeight: '20px',
                    userSelect: 'none',
                  }),
                  noOptionsMessage: (provided) => ({
                    ...provided,
                    textColor: 'brand.wildBlueYonder',
                    fontSize: isTouchscreenDevice ? 'xm' : 'xs',
                  }),
                  ...chakraStyles,
                }}
                isInvalid={Boolean(errorMessage) || isInvalid}
                isMulti={isMulti}
                {...rest}
              />
              {rightElement && (
                <Flex
                  w="fit-content"
                  alignItems="center"
                  {...(!hasInputInRightElement && {
                    mt: '4px',
                    h: '44px',
                    borderColor: 'brand.gainsboro',
                    borderLeft: 'none',
                    borderRadius: 'sm',
                    borderStartRadius: 'none',
                    borderWidth: '1px',
                  })}
                >
                  {rightElement}
                </Flex>
              )}
            </Flex>
            {hasRequiredLabel && !errorMessage && (
              <Text mt="4px" variant="warning">
                {t('TRANSLATION_57')}
              </Text>
            )}
            {errorMessage && (
              <Text
                variant="error"
                mt="4px"
                textAlign="left"
                {...(styleVariant === DropdownVariant.White && {
                  color: 'state.lightError',
                })}
              >
                {errorMessage}
              </Text>
            )}
          </Flex>
        </Stack>
      </FormControl>
    )
  }
)

Dropdown.displayName = 'Dropdown'
