import { Controller, FieldError, useFormContext } from 'react-hook-form'

import { UrlInput } from './UrlInput'

interface FormUrlInputProps {
  id: string
  label?: string
  placeholder?: string
  isDisabled?: boolean
}

export const FormUrlInput = ({
  id,
  label,
  placeholder,
  isDisabled,
  ...rest
}: FormUrlInputProps) => {
  const { control, formState } = useFormContext()
  return (
    <Controller
      name={id}
      control={control}
      render={({ field: { onChange, value } }) => (
        <UrlInput
          id={id}
          label={label}
          placeholder={placeholder}
          errorMessage={
            (formState.errors[id] as FieldError | undefined)?.message
          }
          {...rest}
          onChange={onChange}
          value={value}
          {...(isDisabled && { isDisabled })}
        />
      )}
    />
  )
}
