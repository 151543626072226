import { ReactNode } from 'react'

import {
  HStack,
  Radio as ChakraRadio,
  RadioGroup as ChakraRadioGroup,
  RadioGroupProps as ChakraRadioGroupProps,
  SimpleGrid,
  Text,
} from '@chakra-ui/react'

export interface RadioGroupProps
  extends Omit<ChakraRadioGroupProps, 'children'> {
  label?: string
  id: string
  values: string[]
  tooltip?: ReactNode
}

export const RadioGroup = ({
  label: radioGroupLabel,
  id,
  values,
  tooltip,
  ...rest
}: RadioGroupProps) => (
  <SimpleGrid gridTemplateColumns="minmax(100px, 30%) auto">
    <HStack>
      <Text fontSize="13px">{radioGroupLabel}</Text>
      {tooltip}
    </HStack>
    <ChakraRadioGroup
      display="grid"
      gridTemplateColumns={`repeat(${values.length}, 1fr)`}
      justifyItems="center"
      id={id}
      name={id}
      {...rest}
    >
      {values.map((value) => (
        <ChakraRadio key={value} value={value} />
      ))}
    </ChakraRadioGroup>
  </SimpleGrid>
)
