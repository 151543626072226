import { useFormContext } from 'react-hook-form'

import { Checkbox, CheckboxProps } from './Checkbox'

export const FormCheckbox = ({ id, ...rest }: CheckboxProps) => {
  const { register, watch } = useFormContext()

  const isChecked = watch(id)

  return <Checkbox isChecked={isChecked} id={id} {...rest} {...register(id)} />
}
