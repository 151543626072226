import React from 'react'

import {
  chakra,
  CheckboxProps,
  Flex,
  Input,
  Text,
  useCheckbox,
} from '@chakra-ui/react'

interface BuzzwordCheckboxProps extends CheckboxProps {
  id: string
  value: string
  label: string
}

const BuzzwordCheckbox = ({
  id,
  value,
  label,
  ...rest
}: BuzzwordCheckboxProps) => {
  const { state, getCheckboxProps, getInputProps, htmlProps } = useCheckbox({
    id,
    value,
    ...rest,
  })

  return (
    <chakra.label id={id} {...htmlProps} width="fit-content">
      <Input {...getInputProps()} hidden />
      <Flex
        width="fit-content"
        border="1px solid"
        borderColor="brand.brightGray"
        borderRadius="3px"
        cursor="pointer"
        _checked={{
          borderColor: 'brand.purpleNavy',
          backgroundColor: 'brand.purpleNavy',
        }}
        px="8px"
        py="6px"
        {...getCheckboxProps()}
      >
        <Text
          color={state.isChecked ? 'brand.ghostWhite' : 'brand.americanBlue '}
        >
          {label}
        </Text>
      </Flex>
    </chakra.label>
  )
}

export default BuzzwordCheckbox
