import { MutableRefObject, useRef } from 'react'

import { Box, Center, SpaceProps, Text } from '@chakra-ui/react'
import { add, isSameDay } from 'date-fns'
import Image from 'next/legacy/image'
import ReactDatePicker from 'react-datepicker'

import { TextInput } from 'components/Inputs/TextInput'

import 'react-datepicker/dist/react-datepicker.css'
import { DatePickerStyles } from './datePickerStyles'

type NullableDate = Date | null | undefined

export type DatePickerChangeType = NullableDate | [NullableDate, NullableDate]

export interface DatePickerProps extends SpaceProps {
  defaultValue?: string | number | readonly string[]
  minDate?: Date
  maxDate?: Date
  selected?: Date
  startDate?: NullableDate
  endDate?: NullableDate
  monthsShown?: number
  placeholder?: string
  errorMessage?: string
  tooltip?: string
  onChangeDate: (value: DatePickerChangeType) => void
  onBlur?: () => void
  isInclusiveEndDay?: boolean
  isRangeSelect?: boolean
}

const TODAY = new Date()

export const getStartEndDates = (dates: DatePickerChangeType) => {
  if (!Array.isArray(dates)) {
    return {}
  }
  const [start, end] = dates
  if (start && end && isSameDay(start, end)) {
    return {}
  }

  return {
    startDate: start,
    endDate: end,
  }
}

export const DatePicker = ({
  onChangeDate,
  onBlur,
  minDate = TODAY,
  maxDate,
  startDate,
  endDate,
  placeholder,
  errorMessage,
  isRangeSelect = false,
  selected,
  isInclusiveEndDay = false,
  ...spaceProps
}: DatePickerProps) => {
  const ref = useRef<
    ReactDatePicker<string>
  >() as MutableRefObject<ReactDatePicker>

  return (
    <Box {...spaceProps}>
      <DatePickerStyles>
        <ReactDatePicker
          peekNextMonth
          showMonthDropdown
          showYearDropdown
          dropdownMode="select"
          popperPlacement="bottom"
          onChange={(dateChange) => {
            if (!isRangeSelect) {
              onChangeDate(dateChange)
              return
            }
            const { startDate: start, endDate: end } =
              getStartEndDates(dateChange)

            if (isRangeSelect && !start && !end) {
              onChangeDate(null)
            }
            if (isInclusiveEndDay) {
              onChangeDate([
                start,
                end && add(end, { hours: 23, minutes: 59, seconds: 59 }),
              ])
            } else {
              onChangeDate([start, end])
            }
          }}
          ref={ref}
          onBlur={onBlur}
          startDate={startDate}
          endDate={endDate}
          minDate={minDate}
          maxDate={maxDate}
          selected={selected}
          customInput={
            <TextInput
              id="datepicker"
              rightElement={
                <Center w="2.5rem" cursor="pointer">
                  <Image
                    alt="calendar icon"
                    src="/icons/calendar.svg"
                    width={16}
                    height={16}
                    layout="fixed"
                    onClick={() => {
                      ref.current?.setOpen(true)
                    }}
                  />
                </Center>
              }
              {...(placeholder && { placeholderText: placeholder })}
            />
          }
          selectsRange={isRangeSelect}
          shouldCloseOnSelect
          showDisabledMonthNavigation
          disabledKeyboardNavigation
          dateFormat="dd/MM/yyyy"
        />
      </DatePickerStyles>
      {errorMessage && (
        <Box mt="4px">
          <Text variant="error" mt="0">
            {errorMessage}
          </Text>
        </Box>
      )}
    </Box>
  )
}
