import { Controller, useFormContext } from 'react-hook-form'

import { RadioGroup, RadioGroupProps } from './RadioGroup'

interface FormRadioProps extends RadioGroupProps {
  id: string
}

export const FormRadioGroup = ({ id, ...rest }: FormRadioProps) => {
  const { control } = useFormContext()

  return (
    <Controller
      name={id}
      control={control}
      render={({ field: { onChange, onBlur, value } }) => (
        <RadioGroup
          id={id}
          onChange={onChange}
          onBlur={onBlur}
          value={value}
          {...rest}
        />
      )}
    />
  )
}
