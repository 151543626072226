import { useCallback, useEffect, useMemo, useState } from 'react'

import { Flex, Text, VStack } from '@chakra-ui/react'
import { ActionMeta } from 'chakra-react-select'
import useTranslation from 'next-translate/useTranslation'

import { DropdownOption } from 'components/Inputs/Dropdown/Dropdown'
import { PlaceAutocomplete, usePlacesQuery } from 'generated/generated-graphql'
import { useDebouncedInput } from 'hooks/useDebouncedInput'
import { useRegion } from 'providers/RegionProvider'

import { FormDropdown } from '../Dropdown'

type AddressSelectOption = {
  placeId: string
  placeName: string
} & DropdownOption

interface AddressSearchProps {
  id: string
  label: string
  placeholder: string
  error?: string
  initialValue?: PlaceAutocomplete
  sessionToken?: string
  hasPostCodeField?: boolean
  currentPostCode?: string
  onBlur?: () => void
  externalOnChange?: () => void
  onValueSelected?: (
    newValue: AddressSelectOption,
    actionMeta: ActionMeta<unknown> | undefined
  ) => void
  hasRequiredLabel?: boolean
}

export const AddressSearch = ({
  id,
  label,
  placeholder,
  error,
  initialValue,
  sessionToken,
  hasPostCodeField,
  currentPostCode,
  onValueSelected,
  onBlur,
  externalOnChange,
  hasRequiredLabel = false,
}: AddressSearchProps) => {
  const { t } = useTranslation('common')
  const { region } = useRegion()
  const [searchValue, setSearchValue] = useState(initialValue?.placeName ?? '')
  const [searchSelection, setSearchSelection] = useState('')
  const [searchQuery, setSearchQuery] = useState(initialValue?.placeName ?? '')
  const [hasSelectedDefaultOption, setHasSelectedDefaultOption] =
    useState(false)

  const { debouncedValue: debouncedSearchQuery } =
    useDebouncedInput(searchQuery)
  const placesResponse = usePlacesQuery({
    skip: !debouncedSearchQuery || !sessionToken,
    variables: {
      input: {
        searchQuery: debouncedSearchQuery,
        sessionToken: sessionToken ?? '',
        region,
      },
    },
  })

  const selectPlace = useCallback(
    (
      option: AddressSelectOption,
      actionMeta: ActionMeta<unknown> | undefined = undefined
    ) => {
      onValueSelected?.(option, actionMeta)
      setSearchSelection(option.label)
      setSearchValue(option.label)
    },
    [onValueSelected]
  )

  const addressSearchOptions = useMemo(
    () =>
      placesResponse.data?.places?.map(
        ({ placeId, placeName }) =>
          ({
            label: placeName,
            value: placeId,
            placeId,
            placeName,
          }) as AddressSelectOption
      ) ?? [],
    [placesResponse.data?.places]
  )

  useEffect(() => {
    if (
      !hasSelectedDefaultOption &&
      addressSearchOptions.length >= 1 &&
      addressSearchOptions[0]
    ) {
      setHasSelectedDefaultOption(true)
      selectPlace(addressSearchOptions[0])
    }
  }, [
    onValueSelected,
    hasSelectedDefaultOption,
    selectPlace,
    addressSearchOptions,
    onBlur,
  ])

  return (
    <Flex direction={{ base: 'column', md: 'row' }} alignItems="baseline">
      <FormDropdown
        id={id}
        placeholder={placeholder}
        label={label}
        errorMessage={error}
        isSearchable
        value={searchSelection}
        inputValue={searchValue}
        onInputChange={(newValue, { action }) => {
          if (
            !(
              action === 'menu-close' ||
              action === 'input-blur' ||
              action === 'set-value'
            )
          ) {
            setSearchValue(newValue)
            setSearchQuery(newValue)
          }
        }}
        externalOnChange={(newValue, actionMeta) => {
          const selectedValue = newValue as AddressSelectOption
          if (selectedValue) {
            selectPlace(selectedValue, actionMeta)
            externalOnChange?.()
          }
        }}
        options={addressSearchOptions}
        hasDropdownIcon={false}
        filterOption={() => true}
        onBlur={onBlur}
        hasRequiredLabel={hasRequiredLabel}
        chakraStyles={{
          input: (provided) => ({
            ...provided,
            opacity: 1,
          }),
        }}
      />
      {hasPostCodeField && (
        <VStack
          w="142px"
          ml={{ base: '4.5px', md: '8px' }}
          mt={{ base: '9px', md: 0 }}
          align="start"
          spacing={{ base: 0, md: '16px' }}
        >
          <Text fontSize="13px">{t('VENUE_PROFILE_POSTCODE')}</Text>
          <Text color="brand.wildBlueYonder">
            {currentPostCode && currentPostCode}
          </Text>
        </VStack>
      )}
    </Flex>
  )
}
