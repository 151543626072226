import { FormLabel, HStack, VStack } from '@chakra-ui/react'
import { Controller, useFormContext } from 'react-hook-form'

import { ExplanatoryTooltip } from 'components/common/ExplanatoryTooltip'

import { DatePicker, DatePickerProps, getStartEndDates } from './DatePicker'

interface FormDatePickerProps extends Omit<DatePickerProps, 'onChangeDate'> {
  id: string
  label?: string
  placeholder?: string
}

export const FormDatePicker = ({
  id,
  defaultValue,
  placeholder,
  label,
  tooltip,
  isRangeSelect,
  ...rest
}: FormDatePickerProps) => {
  const { control } = useFormContext()
  return (
    <VStack align="start">
      {label && (
        <HStack>
          <FormLabel variant="input" mr="0">
            {label}
          </FormLabel>
          {tooltip && <ExplanatoryTooltip label={tooltip} />}
        </HStack>
      )}
      <Controller
        name={id}
        control={control}
        defaultValue={defaultValue}
        render={({ field: { onChange, onBlur, value: dates } }) => {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
          const { startDate, endDate } = getStartEndDates(dates)
          return (
            <DatePicker
              onBlur={onBlur}
              {...(isRangeSelect
                ? { startDate, endDate }
                : { selected: dates })}
              {...(placeholder && { placeholder })}
              {...rest}
              onChangeDate={onChange}
            />
          )
        }}
      />
    </VStack>
  )
}
