import { forwardRef, ReactElement } from 'react'

import {
  Box,
  Flex,
  FormLabel,
  HStack,
  Input,
  InputGroup,
  InputLeftAddon,
  InputProps,
  InputRightElement,
  LayoutProps,
  Stack,
  StackDirection,
  StackProps,
  Text,
  VStack,
} from '@chakra-ui/react'
import useTranslation from 'next-translate/useTranslation'

import { ExplanatoryTooltip } from 'components/common/ExplanatoryTooltip'

export enum TextInputVariant {
  White = 'white',
}

export interface TextInputProps extends InputProps {
  id: string
  label?: string
  rightLabel?: ReactElement | string
  subLabel?: string
  labelDirection?: StackDirection
  inputWidth?: LayoutProps['w']
  rightElement?: ReactElement
  errorMessage?: string
  bottomLabel?: string
  leftAddon?: string
  tooltip?: string
  stackProps?: StackProps
  variant?: TextInputVariant
  hasRequiredLabel?: boolean
}

export const TextInput = forwardRef<HTMLInputElement, TextInputProps>(
  (
    {
      id,
      inputWidth = 'full',
      label,
      rightLabel,
      subLabel,
      labelDirection = 'column',
      rightElement,
      errorMessage = '',
      bottomLabel,
      leftAddon,
      tooltip,
      stackProps,
      variant,
      hasRequiredLabel = false,
      ...rest
    },
    ref
  ) => {
    const { t } = useTranslation('common')

    return (
      <Stack
        justifyContent="space-between"
        direction={labelDirection}
        align="start"
        alignItems={labelDirection === 'row' ? 'center' : 'left'}
        spacing="1"
        w={inputWidth}
        style={{ columnGap: '30px' }}
        {...stackProps}
      >
        {label && !subLabel && (
          <Flex justifyContent="space-between">
            <HStack>
              <FormLabel
                variant="input"
                whiteSpace="nowrap"
                mr="0"
                {...(variant === TextInputVariant.White && { color: 'white' })}
              >
                {label}
              </FormLabel>
              {tooltip && <ExplanatoryTooltip label={tooltip} />}
            </HStack>
            {typeof rightLabel === 'string' ? (
              <FormLabel variant="input" whiteSpace="nowrap" mr="0">
                {rightLabel}
              </FormLabel>
            ) : (
              rightLabel
            )}
          </Flex>
        )}
        {label && subLabel && (
          <VStack spacing={0} alignItems="start">
            <HStack>
              <Text fontSize="13px">{label}</Text>
              {tooltip && <ExplanatoryTooltip label={tooltip} />}
            </HStack>
            <Text color="brand.wildBlueYonder" fontSize="13px">
              {subLabel}
            </Text>
          </VStack>
        )}
        <Box>
          <InputGroup justifyContent="flex-end">
            {leftAddon && <InputLeftAddon>{leftAddon}</InputLeftAddon>}
            <Input
              data-hj-allow
              id={id}
              name={id}
              ref={ref}
              {...(variant === TextInputVariant.White && {
                backgroundColor: 'white',
              })}
              {...rest}
            />
            {rightElement && (
              <InputRightElement h="full" w="fit-content" zIndex={0}>
                {rightElement}
              </InputRightElement>
            )}
          </InputGroup>

          <Flex justifyContent="space-between">
            {(hasRequiredLabel || errorMessage) && (
              <Box mt="4px">
                {hasRequiredLabel && !errorMessage && (
                  <Text variant="warning">{t('TRANSLATION_57')}</Text>
                )}
                {errorMessage && (
                  <Text
                    variant="error"
                    mt="0"
                    {...(variant === TextInputVariant.White && {
                      color: 'state.lightError',
                    })}
                    textAlign="left"
                  >
                    {errorMessage}
                  </Text>
                )}
              </Box>
            )}
            <Box mt="4px">
              <Text variant="bottomLabel" mt="0">
                {bottomLabel}
              </Text>
            </Box>
          </Flex>
        </Box>
      </Stack>
    )
  }
)

TextInput.displayName = 'TextInput'
