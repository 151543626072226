import { chakra } from '@chakra-ui/react'
import { math, rem } from 'polished'

const DAY_SIZE = rem('28px')

export const DatePickerStyles = chakra('div', {
  baseStyle: {
    '.react-datepicker__header': {
      backgroundColor: 'brand.brightGray',
    },
    '.react-datepicker': {
      fontFamily: 'Proxima Nova',
      border: 'unset',
      boxShadow: 'lg',
    },
    '.react-datepicker__month': {
      minHeight: math(`${DAY_SIZE} * 6`),
    },
    '.react-datepicker__day, .react-datepicker__day-name': {
      userSelect: 'none',
      width: DAY_SIZE,
      lineHeight: DAY_SIZE,
      margin: 0,
    },
    '.react-datepicker__day': {
      fontSize: 'xs',
      color: 'brand.darkBlueGray',
      '&, &:hover': {
        borderRadius: 0,
      },
    },
    '.react-datepicker__day--today': {
      fontWeight: 'inherit',
    },
    '.react-datepicker__day--in-range, .react-datepicker__day--in-selecting-range':
      {
        backgroundColor: 'brand.gainsboro',
      },
    '.react-datepicker__day:hover, .react-datepicker__day--selected, .react-datepicker__day--keyboard-selected, .react-datepicker__day--range-start, .react-datepicker__day--range-end, .react-datepicker__day--selecting-range-start, .react-datepicker__day--selecting-range-end':
      {
        backgroundColor: 'brand.gainsboro',
        color: 'brand.darkBlueGray',
      },
    '.react-datepicker__day--range-start, .react-datepicker__day--selecting-range-start, .react-datepicker__day--selecting-range-end, .react-datepicker__day--range-end':
      {
        backgroundColor: 'brand.purpleNavy',
        color: 'white',
      },
    '.react-datepicker__day--disabled': {
      color: 'brand.brightGray',

      '&:hover': {
        backgroundColor: 'transparent !important',
        cursor: 'not-allowed',
      },
    },
    '.react-datepicker__day--outside-month': {
      visibility: 'hidden',
      pointerEvents: 'none',
    },
    '.react-datepicker__day-name': {
      fontSize: 'xs',
      fontWeight: 'semibold',
      color: 'brand.purpleNavy',
    },
    '.react-datepicker__day-names > *': {
      textTransform: 'none',
    },
    '.react-datepicker__month-container': {
      float: { base: 'none', md: 'left' },
    },
  },
})
