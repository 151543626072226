import React from 'react'

import { Input, InputProps } from '@chakra-ui/react'
import useTranslation from 'next-translate/useTranslation'

interface RangeInputProps extends InputProps {
  defaultValue: number
  value: number
  isMin?: boolean
}

export const RangeInput: React.VFC<RangeInputProps> = ({
  defaultValue,
  value,
  isMin,
  ...rest
}) => {
  const isChanged = value !== defaultValue
  const { t } = useTranslation('common')
  return (
    <Input
      w="100%"
      maxW="80px"
      h="32px"
      defaultValue={defaultValue}
      min={defaultValue}
      placeholder={`${
        isMin ? t('COMMON_FILTER_VOLUME_MIN') : t('COMMON_FILTER_VOLUME_MAX')
      } ${defaultValue}`}
      type="number"
      value={isChanged ? value : ''}
      color={isChanged ? 'brand.darkSlateBlue' : 'brand.wildBlueYonder'}
      {...rest}
    />
  )
}
