import {
  forwardRef,
  MutableRefObject,
  useImperativeHandle,
  useRef,
} from 'react'

import { Input, InputProps } from '@chakra-ui/react'
import { map } from 'lodash'
import useTranslation from 'next-translate/useTranslation'
import { v4 as uuidv4 } from 'uuid'

import { FileContext } from 'generated/generated-graphql'
import { useErrorToast } from 'utils/toast'

const MIB_IN_BYTES = 1048576
export const KIB_IN_BYTES = 1024

export interface FileInputItem {
  id?: number
  filename: string
  bucketKey: string
  file?: File
  context: FileContext
  fileSizeInKib: number
}

export interface AvatarInputItem extends FileInputItem {
  // this property is present only when user uploads new avatar
  fileAsSrc: string
}

export interface FileInputProps extends Omit<InputProps, 'value'> {
  id: string
  isMultiple?: boolean
  context: FileContext
  value?: FileInputItem[] | FileInputItem
  fileCountLimit?: number
  fileSizeLimitInMiB?: number
  acceptedFileTypes?: string
  onValueChange: (value: FileInputItem[] | FileInputItem) => void
}

export const FileInput = forwardRef<
  Pick<HTMLInputElement, 'click'>,
  FileInputProps
>(
  (
    {
      id,
      context,
      isMultiple,
      fileCountLimit,
      fileSizeLimitInMiB,
      value,
      onValueChange,
      acceptedFileTypes,
      ...rest
    },
    ref
  ) => {
    const { t } = useTranslation('common')
    const showErrorToast = useErrorToast()
    const inputRef =
      useRef<HTMLInputElement>() as MutableRefObject<HTMLInputElement>
    useImperativeHandle(ref, () => ({
      click: () => {
        inputRef?.current?.click()
      },
    }))
    return (
      <Input
        id={id}
        name={id}
        ref={inputRef}
        {...rest}
        multiple={isMultiple}
        accept={acceptedFileTypes}
        onChange={(e) => {
          if (!e.target.files) {
            return
          }
          const newValues = Object.values(e.target.files).map((file) => ({
            file,
            filename: file.name,
            context,
            bucketKey: isMultiple ? uuidv4() : id,
            fileSizeInKib: file.size / KIB_IN_BYTES,
          }))
          const currentFileCount = Array.isArray(value) ? value.length : 1
          const isViolatingFileLimit =
            fileCountLimit &&
            currentFileCount + newValues.length > fileCountLimit
          if (isViolatingFileLimit) {
            showErrorToast({
              // @ts-ignore
              description: t('TRANSLATION_58', {
                fileCountLimit,
                amount: fileCountLimit - currentFileCount,
              }),
            })
            return
          }

          const isViolatingSizeLimit =
            fileSizeLimitInMiB &&
            map(newValues, 'file').some(
              ({ size }) => size / MIB_IN_BYTES > fileSizeLimitInMiB
            )
          if (isViolatingSizeLimit) {
            showErrorToast({
              // @ts-ignore
              description: t('TRANSLATION_59', {
                fileSizeLimitInMiB,
              }),
            })
            return
          }

          if (isMultiple && Array.isArray(value)) {
            const newValuesToSet = [...value, ...newValues]

            const isDuplicity =
              new Set(newValuesToSet.map((val) => val.filename)).size !==
              newValuesToSet.length

            if (isDuplicity) {
              showErrorToast({
                description: t('TRANSLATION_60'),
              })
              return
            }

            onValueChange(newValuesToSet)
          } else if (newValues[0]) {
            onValueChange(newValues[0])
          }
          if (inputRef.current) {
            // Reset input value, so we can select the same file N times in a row
            inputRef.current.value = ''
          }
        }}
        type="file"
        display="none"
      />
    )
  }
)

FileInput.displayName = 'FileInput'
