import { useFormContext } from 'react-hook-form'

import { TextInput, TextInputProps } from './TextInput'

export const FormTextInput = ({
  id,
  errorMessage,
  externalOnChange,
  ...rest
}: TextInputProps & { externalOnChange?: (value: string) => void }) => {
  const { register } = useFormContext()

  const registerProps = register(id)

  return (
    <TextInput
      id={id}
      errorMessage={errorMessage}
      {...registerProps}
      onChange={(event) => {
        externalOnChange?.(event.target.value)
        void registerProps.onChange(event)
      }}
      {...rest}
    />
  )
}
