import { forwardRef, ReactNode } from 'react'

import {
  Checkbox as ChakraCheckbox,
  CheckboxProps as ChakraCheckboxProps,
  Icon,
  StackProps,
  Text,
  TextProps,
  VStack,
} from '@chakra-ui/react'

const CustomCheckboxIcon = ({ isChecked }: { isChecked?: boolean }) => (
  <Icon viewBox="0 0 12 8">
    <path
      stroke="white"
      fill="none"
      {...(isChecked && { d: 'M1.50366 4L4.50366 7L10.5037 1' })}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Icon>
)

export interface CheckboxProps extends ChakraCheckboxProps {
  label?: string
  description?: string | ReactNode
  id: string
  variant?: string
  wrapperStyleProps?: StackProps
  descriptionProps?: TextProps
}

export const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(
  (
    {
      label,
      description,
      id,
      wrapperStyleProps,
      variant = 'primary',
      descriptionProps,
      ...rest
    },
    ref
  ) => (
    <VStack spacing="0" align="start" {...wrapperStyleProps}>
      <ChakraCheckbox
        h="4"
        id={id}
        name={id}
        {...rest}
        ref={ref}
        variant={variant}
        icon={<CustomCheckboxIcon />}
        borderRadius="4px"
      >
        {label}
      </ChakraCheckbox>
      {typeof description === 'string' ? (
        <Text
          pl="6"
          fontSize="xm"
          lineHeight="shorter"
          fontFamily="Proxima Nova"
          color="brand.wildBlueYonder"
          {...descriptionProps}
        >
          {description}
        </Text>
      ) : (
        description
      )}
    </VStack>
  )
)

Checkbox.displayName = 'Checkbox'
