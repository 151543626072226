import { forwardRef } from 'react'

import {
  Box,
  Flex,
  FormLabel,
  HStack,
  RangeSlider as ChakraRangeSlider,
  RangeSliderFilledTrack,
  RangeSliderProps as ChakraRangeSliderProps,
  RangeSliderThumb,
  RangeSliderTrack,
  Text,
  VStack,
} from '@chakra-ui/react'

import { ExplanatoryTooltip } from 'components/common/ExplanatoryTooltip'

import { RangeInput } from './RangeInput'
import { RangeValue } from './RangeValue'

export interface RangeSliderProps
  extends Omit<ChakraRangeSliderProps, 'value'> {
  id: string
  minValue: number
  maxValue: number
  defaultValues?: [number, number]
  errorMessage?: string
  label?: string
  tooltip?: string
  step: number
  value: { min: number; max: number }
  hasInputFields?: boolean
  onChange: ([minSelectedValue, maxSelectedValue]: [number, number]) => void
  setIsTouched?: (value: boolean) => void
}

export const RangeSlider = forwardRef<HTMLDivElement, RangeSliderProps>(
  (
    {
      minValue,
      maxValue,
      id,
      label,
      errorMessage,
      tooltip,
      defaultValues,
      step,
      value,
      hasInputFields = false,
      onChange,
      setIsTouched,
      ...rest
    },
    ref
  ) => (
    <VStack w="full" align="left" spacing="2">
      {label && (
        <HStack spacing="2">
          <FormLabel variant="input" whiteSpace="nowrap" mr="0">
            {label}
          </FormLabel>
          {tooltip && <ExplanatoryTooltip label={tooltip} />}
        </HStack>
      )}
      <Flex w="full" justifyContent="space-between">
        {hasInputFields ? (
          <RangeInput
            isMin
            defaultValue={minValue}
            value={value.min}
            onChange={({ target }) => {
              onChange([Number(target.value), value.max])
            }}
            onBlur={() => {
              if (value.min < minValue) {
                onChange([minValue, value.max])
              }
              if (value.min > value.max) {
                onChange([value.max - 1, value.max])
              }
              setIsTouched?.(true)
            }}
          />
        ) : (
          <RangeValue mr="2" value={value.min} isMin />
        )}
        <ChakraRangeSlider
          // eslint-disable-next-line jsx-a11y/aria-proptypes
          aria-label={['min', 'max']}
          ref={ref}
          id={id}
          name={id}
          defaultValue={defaultValues}
          step={step}
          min={minValue}
          max={maxValue}
          value={[value.min, value.max]}
          onChange={onChange}
          {...rest}
        >
          <RangeSliderTrack>
            <RangeSliderFilledTrack bgColor="brand.ultramarine" />
          </RangeSliderTrack>
          <RangeSliderThumb
            borderWidth={1}
            borderColor="white"
            bgColor="brand.ultramarine"
            index={0}
          />
          <RangeSliderThumb
            borderWidth={1}
            borderColor="white"
            bgColor="brand.ultramarine"
            index={1}
          />
        </ChakraRangeSlider>
        {hasInputFields ? (
          <RangeInput
            defaultValue={maxValue}
            value={value.max}
            onChange={({ target }) => {
              onChange([value.min, Number(target.value)])
            }}
            onBlur={() => {
              if (value.max > maxValue) {
                onChange([value.min, maxValue])
              }
              if (value.max < value.min) {
                onChange([value.min, value.min + 1])
              }
              setIsTouched?.(true)
            }}
          />
        ) : (
          <RangeValue ml="2" value={value.max} isMin={false} />
        )}
      </Flex>

      {errorMessage && (
        <Box mt="4px">
          <Text variant="error" mt="0">
            {errorMessage}
          </Text>
        </Box>
      )}
    </VStack>
  )
)

RangeSlider.displayName = 'RangeSlider'
