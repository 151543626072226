import React from 'react'

import {
  Box,
  CheckboxGroup,
  Flex,
  FlexProps,
  Text,
  useCheckboxGroup,
} from '@chakra-ui/react'
import { difference, intersection } from 'lodash'
import useTranslation from 'next-translate/useTranslation'
import { Controller, useFormContext } from 'react-hook-form'

import { RatingFeedbackValue } from 'generated/generated-graphql'

import BuzzwordCheckbox from './BuzzwordCheckbox'

interface FormBuzzwordCheckboxGroupProps extends FlexProps {
  id: string
  values: RatingFeedbackValue[]
  defaultValue?: string[]
  currentValues?: string[]
  errorMessage?: string
}

const FormBuzzwordCheckboxGroup = ({
  id,
  values,
  errorMessage,
  defaultValue,
  currentValues,
  ...rest
}: FormBuzzwordCheckboxGroupProps) => {
  const { t } = useTranslation('common')
  const { control, setValue } = useFormContext()
  const { getCheckboxProps } = useCheckboxGroup({
    defaultValue: intersection(
      defaultValue,
      values.map(({ key }) => key)
    ),
    onChange: (selectedValue) => {
      const selectedValues = difference(
        currentValues,
        values.map(({ key }) => key)
      )

      setValue(
        id,
        selectedValues ? [...selectedValues, ...selectedValue] : selectedValue
      )

      return
    },
  })

  return (
    <Controller
      name={id}
      control={control}
      render={() => (
        <CheckboxGroup>
          <Flex
            w="full"
            wrap="wrap"
            gridRowGap="8px"
            gridColumnGap="12px"
            justifyContent="center"
            {...rest}
          >
            <>
              {values.map(({ key }) => (
                <BuzzwordCheckbox
                  id={key}
                  key={key}
                  value={key}
                  label={t(`bidRatingOptions.${key}`)}
                  {...getCheckboxProps({
                    value: key,
                  })}
                />
              ))}
              {errorMessage && (
                <Box w="full" textAlign="left">
                  <Text variant="error" mt="0">
                    {errorMessage}
                  </Text>
                </Box>
              )}
            </>
          </Flex>
        </CheckboxGroup>
      )}
    />
  )
}

export default FormBuzzwordCheckboxGroup
