import { forwardRef, ReactElement } from 'react'

import {
  Box,
  FormLabel,
  HStack,
  Input,
  InputGroup,
  InputLeftAddon,
  InputProps,
  InputRightElement,
  LayoutProps,
  Stack,
  StackDirection,
  Text,
  VStack,
} from '@chakra-ui/react'

import { ExplanatoryTooltip } from 'components/common/ExplanatoryTooltip'
import { validateUrl } from 'utils/url'

export interface UrlInputProps extends Omit<InputProps, 'onChange'> {
  id: string
  label?: string
  subLabel?: string
  labelDirection?: StackDirection
  inputWidth?: LayoutProps['w']
  rightElement?: ReactElement
  errorMessage?: string
  leftAddon?: string
  tooltip?: string
  onChange: (value: string) => void
}

export const UrlInput = forwardRef<HTMLInputElement, UrlInputProps>(
  ({
    id,
    inputWidth = 'full',
    label,
    subLabel,
    labelDirection = 'column',
    rightElement,
    errorMessage = '',
    leftAddon,
    tooltip,
    onChange,
    value,
    ...rest
  }) => (
    <Stack
      justifyContent="space-between"
      direction={labelDirection}
      align="start"
      alignItems={labelDirection === 'row' ? 'center' : 'left'}
      spacing="1"
      w={inputWidth}
      flexWrap="wrap"
    >
      {label && !subLabel && (
        <HStack spacing="2">
          <FormLabel variant="input" whiteSpace="nowrap" mr="0">
            {label}
          </FormLabel>
          {tooltip && <ExplanatoryTooltip label={tooltip} />}
        </HStack>
      )}
      {label && subLabel && (
        <VStack spacing={0} alignItems="start">
          <HStack spacing="2">
            <Text fontSize="13px">{label}</Text>
            {tooltip && <ExplanatoryTooltip label={tooltip} />}
          </HStack>
          <Text color="brand.wildBlueYonder" fontSize="13px">
            {subLabel}
          </Text>
        </VStack>
      )}
      <Box>
        <InputGroup justifyContent="flex-end">
          {leftAddon && <InputLeftAddon>{leftAddon}</InputLeftAddon>}
          <Input
            data-hj-allow
            id={id}
            name={id}
            value={value}
            {...rest}
            onChange={(e) => {
              onChange(e.target.value)
            }}
            onBlur={(e) => {
              const urlWithoutProtocols = e.target.value.replace(
                /^(?:https?:\/\/)?(?:www\.)?/i,
                ''
              )
              if (validateUrl({ url: urlWithoutProtocols })) {
                onChange(`http://www.${urlWithoutProtocols}`)
              }
            }}
          />
          {rightElement && (
            <InputRightElement h="full" w="fit-content">
              {rightElement}
            </InputRightElement>
          )}
        </InputGroup>
        {errorMessage && (
          <Box mt="4px">
            <Text variant="error" mt="0">
              {errorMessage}
            </Text>
          </Box>
        )}
      </Box>
    </Stack>
  )
)

UrlInput.displayName = 'UrlInput'
