import { MutableRefObject, ReactElement, useRef } from 'react'

import { Box, Button, Flex, FormLabel, Stack, Text } from '@chakra-ui/react'
import useTranslation from 'next-translate/useTranslation'
import { Controller, useFormContext } from 'react-hook-form'

import { FileInput, FileInputItem, FileInputProps } from './FileInput'
import { Dropdown } from '../Dropdown'

interface FormFileInputProps extends FileInputProps {
  label?: string
  subLabel?: string
  rightElement?: ReactElement
  errorMessage?: string
  isMultiple?: boolean
  buttonText?: string
  isWithShowFiles?: boolean
  explanation?: string
}

export const FormFileInput = ({
  id,
  errorMessage,
  label,
  placeholder,
  subLabel,
  buttonText,
  explanation,
  ...rest
}: Omit<FormFileInputProps, 'value' | 'onValueChange'>) => {
  const { t } = useTranslation('common')
  const { control } = useFormContext()
  const fileInputRef =
    useRef<HTMLInputElement>() as MutableRefObject<HTMLInputElement>
  return (
    <Controller
      name={id}
      control={control}
      render={({ field }) => {
        const { value = [] }: { value: FileInputItem[] | undefined } = field

        const formattedValues = value.map(({ filename, bucketKey }) => ({
          label: filename,
          value: bucketKey,
        }))

        return (
          <>
            <Stack
              justifyContent="space-between"
              align="start"
              spacing="1"
              w="full"
            >
              {label && (
                <FormLabel variant="input" whiteSpace="nowrap">
                  {label}
                </FormLabel>
              )}
              <Flex w="full" flexWrap="wrap" style={{ gap: '12px' }}>
                <Flex flexDirection="column" flex="1 1 62%" mb="6px">
                  <Dropdown
                    id={`${id}-filenames`}
                    placeholder={
                      placeholder ?? t('TENDER_CREATION_ADD_FILES_BUTTON')
                    }
                    options={formattedValues}
                    value={formattedValues}
                    onChange={(newValue) => {
                      if (Array.isArray(newValue)) {
                        const newFileNames = newValue.map(
                          ({ label: optionLabel }) => optionLabel
                        )
                        field.onChange(
                          value.filter(({ filename }) =>
                            newFileNames.includes(filename)
                          )
                        )
                      }
                    }}
                    onMenuOpen={() => {
                      fileInputRef.current?.click()
                    }}
                    isMulti
                    hasDropdownIcon={false}
                    menuIsOpen={false}
                  />
                  {subLabel && (
                    <Text color="brand.wildBlueYonder" fontSize="13px" ml="5px">
                      {subLabel}
                    </Text>
                  )}
                  {explanation && (
                    <Text
                      color="brand.wildBlueYonder"
                      fontSize="13px"
                      as="i"
                      ml="5px"
                    >
                      {explanation}
                    </Text>
                  )}
                </Flex>
                <Button
                  variant="outline"
                  size="lg"
                  onClick={() => {
                    fileInputRef.current?.click()
                  }}
                >
                  {buttonText ?? t('TENDER_CREATION_ADD_FILES_BUTTON')}
                </Button>
                {errorMessage && (
                  <Box mt="4px">
                    <Text variant="error" mt="0">
                      {errorMessage}
                    </Text>
                  </Box>
                )}
              </Flex>
            </Stack>
            <FileInput
              ref={fileInputRef}
              id={id}
              {...rest}
              value={value}
              onValueChange={field.onChange}
            />
          </>
        )
      }}
    />
  )
}
