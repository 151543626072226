import { ReactNode } from 'react'

import { Flex } from '@chakra-ui/react'
import Image from 'next/legacy/image'

import { Tooltip } from 'components/common/Tooltip'

interface ExplanatoryTooltipProps {
  label: string | ReactNode
  size?: number
}

export const ExplanatoryTooltip = ({
  label,
  size = 12,
}: ExplanatoryTooltipProps) => (
  <Tooltip content={label}>
    <Flex alignContent="center">
      <Image
        alt="tooltip"
        src="/icons/questionmark_tooltip.svg"
        width={size}
        height={size}
      />
    </Flex>
  </Tooltip>
)
