import { useState } from 'react'

import { Box, BoxProps, Flex, Text, TextProps } from '@chakra-ui/react'

import { Star } from 'components/icons'
import { PropsWithChildrenOptional } from 'constants/typings'

const RatingValues = [1, 2, 3, 4, 5]
const DEFAULT_SIZE = 6
const DEFAULT_NUMERIC_STAR_SIZE = 20

export interface RatingProps extends BoxProps {
  ratingValue?: number
  isReadOnly?: boolean
  isRatingOutdated?: boolean
  hasTransition?: boolean
  hasAnimation?: boolean
  canReset?: boolean
  size?: number
  spacing?: number
  numericStarSize?: number
  fillColor?: string
  emptyColor?: string
  variant?: 'star' | 'numeric'
  numericTextProps?: TextProps
  onRatingChange?: (value: number) => void
  onReset?: () => void
}

export const Rating = ({
  ratingValue = 0,
  isReadOnly = false,
  isRatingOutdated = false,
  canReset,
  size = DEFAULT_SIZE,
  spacing = 8,
  numericStarSize = DEFAULT_NUMERIC_STAR_SIZE,
  numericTextProps,
  hasTransition = false,
  hasAnimation = true,
  variant = 'star',
  fillColor = 'brand.orange',
  emptyColor = 'brand.gainsboro',
  children,
  onRatingChange,
  onReset,
  ...rest
}: PropsWithChildrenOptional<RatingProps>) => {
  const [hoverValue, setHoverValue] = useState<number>(0)

  if (variant === 'numeric') {
    return (
      <Flex alignItems="center">
        <Text mr="4px" {...numericTextProps}>
          {ratingValue}
        </Text>
        <Star
          color={ratingValue > 0 ? fillColor : emptyColor}
          width={`${numericStarSize}px`}
          height={`${numericStarSize}px`}
          {...(isRatingOutdated &&
            ratingValue > 0 && {
              stroke: 'brand.orange',
              strokeWidth: 1,
            })}
        />
      </Flex>
    )
  }

  return (
    <Flex {...rest}>
      {RatingValues.map((currentStar) => (
        <Box
          key={currentStar}
          {...(!isReadOnly && {
            onMouseEnter: () => {
              if (hasAnimation) {
                setHoverValue(currentStar)
              }
            },
            onMouseLeave: () => {
              setHoverValue(0)
            },
            onClick: () => {
              if (ratingValue !== currentStar) {
                onRatingChange?.(currentStar)
              } else if (canReset) {
                onReset?.()
              }
            },
            cursor: 'pointer',
          })}
          aria-hidden="true"
          display="inline-flex"
          {...(hasTransition && { transition: 'color 0.2s ease-in-out 0s' })}
          {...(isRatingOutdated
            ? { color: hoverValue >= currentStar ? fillColor : emptyColor }
            : {
                color:
                  (hoverValue || ratingValue) >= currentStar
                    ? fillColor
                    : emptyColor,
              })}
          width={`${size + spacing}px`}
          height={`${size}px`}
        >
          {children || (
            <Star
              key={currentStar}
              width={`${size}px`}
              height={`${size}px`}
              {...(isRatingOutdated &&
                ratingValue >= currentStar && {
                  stroke: 'brand.orange',
                  strokeWidth: 1,
                })}
            />
          )}
        </Box>
      ))}
    </Flex>
  )
}
